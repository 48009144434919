import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Experience from './components/Experience';
import ProjectCards from './components/Projects';
import Hero from './components/Hero';
import Skills from './components/Skills';
import Resume from './components/Resume';
import ParticlesBackground from './components/ParticlesBackground';
import Contact from './components/Contact';
import FloatingImages from './components/FloatingImages';
import { Analytics } from "@vercel/analytics/react";
import { FaPaw, FaSnowflake, FaTimes } from 'react-icons/fa';
import './App.css';
import ContributionGraph from './components/ContributionGraph';

function App() {
  /*
    We'll store an integer in localStorage that represents:
      0 => No background
      1 => ParticlesBackground
      2 => FloatingImages

    We'll call this 'bgMode' instead of 'showParticles'.
  */
  const [bgMode, setBgMode] = useState(() => {
    const saved = localStorage.getItem('bgMode');
    return saved !== null ? JSON.parse(saved) : 1; 
  });

  const handleToggle = () => {
    // Cycle through 0 -> 1 -> 2 -> 0...
    setBgMode(prev => {
      const next = (prev + 1) % 3;
      localStorage.setItem('bgMode', JSON.stringify(next));
      return next;
    });
  };

  let backgroundElement = null;
  if (bgMode === 1) {
    backgroundElement = <ParticlesBackground style={{ position: "fixed", zIndex: -1 }} />;
  } else if (bgMode === 2) {
    backgroundElement = <FloatingImages style={{ position: "fixed", zIndex: -1 }} />;
  }
  // if bgMode is 0, backgroundElement stays null => no background.

  /*
    For the toggle button icon, let's pick:
      - 0 => FaTimes (no background)
      - 1 => FaSnowflake (particles)
      - 2 => FaPaw (floating images)
  */
  let toggleIcon = null;
  if (bgMode === 0) {
    toggleIcon = <FaSnowflake />;
  } else if (bgMode === 1) {
    toggleIcon = <FaPaw />;
  } else {
    toggleIcon = <FaTimes />;
  }

  return (
    <Router>
      {backgroundElement}
      <Navbar />
      <Analytics />
      <button className="floating-button" onClick={handleToggle} title="Toggle Background">
        {toggleIcon}
      </button>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <ContributionGraph username="dakshg3" />
              <Skills />
              <Contact />
            </>
          }
        />
        <Route path="/experience" element={<Experience />} />
        <Route path="/projects" element={<ProjectCards />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </Router>
  );
}

export default App;
