import React from 'react';
import { Card, Col, Row, Tag } from 'antd';
import './ProjectCards.css';
import htmllogo from '../assets/images/html-formatter-logo.png'; 
import suitemate from '../assets/images/suitemate.webp';
import institutionalElective from '../assets/images/institutional-elective-logo.png';
import cloudcomputing from '../assets/images/cloud-computing-logo.png';
import bdaproject from '../assets/images/bdaproject.png';
import notefy from '../assets/images/notefy-logo.png';
import grader from '../assets/images/grader.png';
import semantic_versioning from '../assets/images/semantic_versioning.png';


const { Meta } = Card;

const projects = [
  {
    title: 'SuiteMate - Roommate Finder',
    description: 'A full-stack Next.js web app for roommate matching and lease management, designed with an intuitive, responsive UI and an analytics dashboard for landlords and tenants. A MySQL database on Google Cloud Platform (GCP) provides scalable architecture with efficient data storage via indexed retrieval. RESTful Flask APIs handle user authentication and property management, ensuring secure and scalable operations.',
    image: suitemate,
    technologies: ['NextJS', 'Flask', 'Python','MySQL'],
    githubUrl: 'https://github.com/dakshg3/SuiteMate/tree/main',
  },
  {
    title: 'NoteFy - Course Notes Sharing Application',
    description: 'Android App facilitating seamless uploading, downloading, and rating of subject-specific notes for both students and teachers, streamlining access to high-quality study materials for over 200 subjects.',
    image: notefy,
    technologies: ['Android', 'Firebase', 'Java'],
    githubUrl: 'https://github.com/dakshg3/NoteFy---Notes-Sharing/tree/main',
  },
  {
    title: 'Research - Software Reproducibility Analysis',
    description: 'Analyzed the semantic variations of Software Articles (Dockerfiles) to ensure bitwise reproducibility across Ubuntu versions. Identified common errors, compared package managers (PIP vs apt-get), and implemented LLM-assisted automation for building and fixing Dockerfiles. Key outputs included detailed metrics, error classification, and insights on improving reproducibility.',
    image: semantic_versioning,
    technologies: ['Python', 'Docker', 'Ubuntu', 'LLM (Llama 3.2)', 'NLP', 'Data Analysis'],
    githubUrl: 'https://github.com/dakshg3/Software-Reproducibility---Semantic-Versioning',
  },
  {
    title: 'Institutional Elective',
    description: 'The University Web Portal in Django is for students\' online elective registration. Migrated from PHP to a scalable architecture supporting 4,000 users, improving load balancing and reliability. ',
    image: institutionalElective,
    technologies: ['Django', 'Python','Tkinter','MySQL','REST API'],
    githubUrl: 'https://github.com/dakshg3/Django_Institutional_Elective/tree/main',
  },
  {
    title: 'Cloud Computing Machine Problems',
    description: '[Converted to a Private Repository while I am a Teaching Assistant for this course.] A collection of examples, challenges, and implementations across cloud computing services and frameworks, including AWS EC2, Lambda, Spark, Hadoop, HBase, Redis, and ElastiCache, with additional functionality for graph processing and BFS algorithms. Developed as part of a Master\'s course in Computer Science, focusing on Cloud Computing Applications.',
    image: cloudcomputing,
    technologies: ['AWS EC2', 'AWS Lambda','Spark', 'Hadoop', 'HBase', 'Redis', 'ElastiCache'],
    // githubUrl: 'https://github.com/dakshg3/CS-498-CCA/tree/main',
  },
  {
    title: 'HTML Formatter for WordPress - Chrome Extension',
    description: 'This extension helps you format your HTML code in WordPress posts and pages. Automatically indents your code and makes it more readable.',
    image: htmllogo,
    technologies: ['Javascript'],
    githubUrl: 'https://github.com/dakshg3/html-formatter-extension/tree/main',
  },
  {
    title: 'Password Leak Analysis - Big Data Analytics',
    description: 'A dataset of over 5 million leaked passwords was compiled, cleaned, and consolidated into a single MongoDB instance based on password weight. Map-reduce operations were then performed, and results were visualized on various charts to identify trends and patterns, uncovering widespread insecurity and the overuse of common passwords globally.',
    image: bdaproject,
    technologies: ['Python', 'MongoDB', 'MapReduce', 'Data Analysis'],
    githubUrl: 'https://github.com/dakshg3/PasswordLeakAnalyzer',
  },
  {
    title: 'Assignment Grader Extension',
    description: 'A Chrome extension that extracts the text from an open PDF in your browser, sends it along with a user-defined prompt to a Large Language Model (LLM) hosted on Hugging Face, basically to interact with PDFs files, designed specifically to grade PDF assignment submissions. Works great with Gradescope as well.',
    image: grader,
    technologies: ['Javascript', 'Hugging Face LLM API'],
    githubUrl: 'https://github.com/dakshg3/assignment-grader-extension',
  }
];

function ProjectCards() {
  return (
    <div className="projects-container">
      <h2 className="projects-heading">Projects</h2>
      <Row gutter={[16, 16]}>
        {projects.map((project, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <a
              href={project.githubUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="project-link"
            >
              <Card
                hoverable
                className="project-card"
                cover={<img alt={project.title} src={project.image} />}
              >
                <Meta
                  title={project.title}
                  description={project.description}
                  className="project-meta"
                />
                <div className="project-tags">
                  {project.technologies.map((tech, idx) => (
                    <Tag key={idx} className="project-tag">
                      {tech}
                    </Tag>
                  ))}
                </div>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ProjectCards;
