import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ContributionGraph.css';

const ContributionGraph = ({ username }) => {
  const [contributions, setContributions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContributions = async () => {
      try {
        const response = await axios.get(
          `https://github-contributions-api.jogruber.de/v4/${username}`
        );
        setContributions(response.data.contributions);
      } catch (err) {
        console.error('Error fetching contributions:', err);
        setError('Failed to load contributions.');
      }
    };

    fetchContributions();
  }, [username]);

  if (error) {
    return <p>{error}</p>;
  }

  if (contributions.length === 0) {
    return <p></p>;
  }


  const getFirstSundayOfYear = (year) => {
    const d = new Date(year, 0, 1); 
    const dayOfWeek = d.getDay(); 
    const diff = (7 - dayOfWeek) % 7;
    d.setDate(d.getDate() + diff);
    return d;
  };

  const startDate = getFirstSundayOfYear(2024);

  const now = new Date();
  const currentYear = now.getFullYear();
  let endDate;

  if (currentYear < 2024) {
    endDate = new Date(2024, 0, 1); 
  } else if (currentYear === 2024) {
    endDate = new Date(2024, now.getMonth() + 1, 0); 
  } else if (currentYear === 2025) {
    endDate = new Date(2025, now.getMonth() + 1, 0); 
  } else {
    endDate = new Date(2025, 11, 31);
  }


  const contributionsInRange = contributions.filter((item) => {
    const date = new Date(item.date);
    const year = date.getFullYear();

    // only keep 2024 or 2025
    if (year < 2024 || year > 2025) {
      return false;
    }
    // must be within startDate and endDate
    return date >= startDate && date <= endDate;
  });

  if (contributionsInRange.length === 0) {
    return <p>No contributions for 2024–2025 up to this month.</p>;
  }


  const maxCount = Math.max(...contributionsInRange.map((d) => d.count));

  // Assign intensities 0-4
  const contributionsWithIntensity = contributionsInRange.map((day) => {
    let intensity = 0;
    if (day.count > 0) {
      const ratio = day.count / maxCount;
      if (ratio >= 0.75) {
        intensity = 4;
      } else if (ratio >= 0.5) {
        intensity = 3;
      } else if (ratio >= 0.25) {
        intensity = 2;
      } else {
        intensity = 1;
      }
    }
    return { ...day, intensity };
  });


  const weeks = [];
  contributionsWithIntensity.forEach((day) => {
    const date = new Date(day.date);
    const dayOfWeek = date.getDay();
    const daysDifference = Math.floor(
      (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    const weekIndex = Math.floor(daysDifference / 7);

    if (weekIndex >= 0) {
      if (!weeks[weekIndex]) {
        weeks[weekIndex] = Array(7).fill(null);
      }
      weeks[weekIndex][dayOfWeek] = day;
    }
  });

  // --------------------------------------------
  // 5) TRANSPOSE DAYS (ROWS => SUN-SAT, COLUMNS => WEEKS)
  // --------------------------------------------
  const transposedDays = [];
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    const row = [];
    for (let weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
      const day = weeks[weekIndex]?.[dayIndex];
      if (day) {
        row.push(day);
      } else {
        row.push({ count: 0, date: '', intensity: 0 });
      }
    }
    transposedDays.push(row);
  }

  // --------------------------------------------
  // 6) MONTH LABELS
  // --------------------------------------------
  const monthLabels = [];
  let lastMonth = null;

  weeks.forEach((week, weekIndex) => {
    if (!week) return;
    const firstDayOfWeek = week.find((d) => d && d.date);
    if (firstDayOfWeek) {
      const date = new Date(firstDayOfWeek.date);
      const month = date.getMonth(); // 0=Jan,1=Feb,...
      const dayOfMonth = date.getDate();

      if (month !== lastMonth && dayOfMonth <= 7) {
        monthLabels.push({ month, index: weekIndex });
        lastMonth = month;
      }
    }
  });

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  return (
    <div className="contribution-graph-container">
      <div className="contribution-graph-card">
        <h2>
          <a
            href={`https://github.com/${username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="github-link"
          >
            GitHub Contributions
          </a>
        </h2>
        <div className="graph-container">
          {/* Month labels */}
          <div className="month-labels">
            <div className="day-label-placeholder"></div>
            {weeks.map((_, weekIndex) => {
              const label = monthLabels.find((m) => m.index === weekIndex);
              return (
                <div key={weekIndex} className="month-label">
                  {label ? monthNames[label.month] : ''}
                </div>
              );
            })}
          </div>

          {/* Heatmap */}
          <div className="graph-content">
            {transposedDays.map((weekDays, dayIndex) => (
              <div key={dayIndex} className="row">
                {weekDays.map((day, weekIndex) => (
                  <div
                    key={weekIndex}
                    className={`day intensity-${day.intensity}`}
                    title={
                      day.date
                        ? `${day.date}: ${day.count} contributions`
                        : 'No data'
                    }
                  ></div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionGraph;
