import React from 'react';
import './Skills.css';

function Skills() {
  const skills = [
    'Cloud (AWS, Azure, GCP, Docker, Kubernetes, Redis, Lambda)',
    'Big Data (Hadoop, Spark)',
    'Networking and Linux',
    'Programming Languages (Python, Bash, C/C++, Java, Javascript)',
    'Python (Django, Flask, Robot Framework)',
    'API (REST API, Postman)',
    'DBMS (SQL and NoSQL)',
    'Automation (Selenium, PlayWright, Jmeter, Jenkins CI)',
    'Next.js',
    'React',
    'Android',
    'System Design',
    'Distributed Systems',
  ];

  /**
   * This helper function looks for the pattern "Outside (Inside)".
   * If it finds parentheses, it returns a JSX fragment with two spans:
   *   1) The text before the parentheses (class "skill-outside")
   *   2) The text inside the parentheses (class "skill-inside")
   */
  function styleParentheses(skillText) {
    // This regex captures two groups:
    //   ^(.*?)\s*\((.*?)\)$
    // Explanation:
    //   - ^(.*?) => capture everything from the start until '(' in group 1
    //   - \s*    => optional whitespace 
    //   - \(     => literal '('
    //   - (.*?)  => capture everything inside the parentheses as group 2
    //   - \)     => literal ')'
    //   - $      => end of string
    const match = skillText.match(/^(.*?)\s*\((.*?)\)$/);

    if (match) {
      const outside = match[1].trim(); // text before '('
      const inside = match[2].trim();  // text inside '(...)'

      return (
        <>
          <span className="skill-outside">{outside}</span>
          {' ('}
          <span className="skill-inside">{inside}</span>
          {')'}
        </>
      );
    }

    // If no parentheses found, return as is
    return <span className="skill-outside">{skillText}</span>;
  }

  return (
    <section className="skills">
      <div className="skills-content">
        <h2 className="skills-heading">Technical Skills</h2>
        <div className="skills-list">
          {skills.map((skill, index) => (
            <div className="skill-item" key={index}>
              {styleParentheses(skill)}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
