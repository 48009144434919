import React from 'react';
import './Resume.css';
import Contact from './Contact';

function Resume() {
  return (
    <div>
      <div className="resume-container" style={{ position: "relative", zIndex: 1 }}>
        <img
          src="/Daksh_Gandhi_Resume.jpg"
          alt="Daksh's Resume"
          className="resume-image"
        />
      </div>
      <div className="resume-download">
        <a
          href="/Daksh_Gandhi_Resume.pdf"
          className="btn"
          download
        >
          Download Resume
        </a>
      </div>
      <Contact />
  </div>
  );
}

export default Resume;
