import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FaBriefcase, FaGraduationCap } from 'react-icons/fa';
import 'react-vertical-timeline-component/style.min.css';

import uiucLogo from '../assets/images/uiucLogo.png';
import akamaiLogo from '../assets/images/akamaiLogo.webp';
import drdoLogo from '../assets/images/drdoLogo.jpeg';
import bmsLogo from '../assets/images/bmsLogo.jpeg';
import './Experience.css';

function Experience() {
  const experiences = [
      {
          type: 'education',
          icon: <FaGraduationCap />,
          image: uiucLogo,
          date: 'Champaign, IL - January 2024 - May 2025',
          title: 'Master of Computer Science',
          location: 'Urbana, IL',
          company: 'University of Illinois Urbana Champaign',
          description: [
              'Teaching Assistant for CS-498: Cloud Computing Applications, guiding students through AWS, Spark, Hadoop, Redis etc.',
              'Relevant Coursework: Distributed Systems, Cloud Computing Applications, Computer Security, Database Systems.',
              '4.0 GPA'
          ],
      },
      {
          type: 'work',
          icon: <FaBriefcase />,
          image: akamaiLogo,
          date: 'Boston, MA - May 2024 - August 2024',
          title: 'Software Engineer - Intern',
          company: 'Akamai Technologies',
          location: 'Boston, MA',
          description: [
              'Developed a full-stack web application with a React frontend and Django backend to manage XML-based network configuration files, handling over 100,000 servers across Akamai’s global delivery network.',
              'Engineered a distributed system component using a queue-polling model to automate the distribution of configuration files across the network, ensuring efficient and reliable file deployment at scale.',
          ],
      },
      {
          type: 'work',
          icon: <FaBriefcase />,
          image: akamaiLogo,
          date: 'Bangalore, India - August 2020 - December 2023',
          title: 'Software Engineer - 2',
          company: 'Akamai Technologies',
          location: 'Bangalore, India',
          description: [
              'SME for two products, addressed issues, and provided support to external teams during interoperability testing.',
              'Lead Engineer, mentoring interns and guiding them on projects while ensuring best practices and successful contributions.',
          ],
      },
      {
          type: 'work',
          icon: <FaBriefcase />,
          image: akamaiLogo,
          date: 'Bangalore, India - Jan 2020 - July 2020',
          title: 'Software Engineer in Test - Intern',
          company: 'Akamai Technologies',
          location: 'Bangalore, India',
          description: [
              'Identified, verified, and automated test cases for new products/features in Python.',
              'Developed Selenium UI Automation Libraries for the Akamai Customer Portal, saving 8 hours weekly.',
          ],
      },
      {
          type: 'work',
          icon: <FaBriefcase />,
          image: drdoLogo,
          date: 'Agra, India - June 2018 - July 2018',
          title: 'Intern',
          company: 'Defense Research And Development Organisation - ADRDE Lab',
          location: 'Agra, India',
          description: [
              'Developed an Android application that tracks the trajectory of an aerial device using an RF module, with real-time mapping integrated into the Google Maps API.',
          ],
      },
      {
          type: 'education',
          icon: <FaGraduationCap />,
          image: bmsLogo,
          date: 'Bangalore, India - August 2016 - August 2020',
          location: 'Bangalore, India',
          title: 'Bachelor of Engineering in Computer Science & Engineering',
          company: 'BMS College of Engineering',
          description: [
              'Completed Computer Science and Engineering. Graduated with a 3.8 GPA.',
              'Relevant Coursework: Data Structures, Algorithms, Operating Systems, Computer Networks and Security.',
          ],
      },
  ];

  return (
      <div className="experience-page">
          <h2 className="experience-heading">Experience</h2>
          <VerticalTimeline>
              {experiences.map((exp, index) => (
                  <VerticalTimelineElement
                      key={index}
                      date={exp.date}
                      location={exp.location}
                      iconStyle={{ background: '#1e90ff', color: '#fff' }}
                      icon={exp.icon}
                      contentStyle={{ background: 'rgba(0, 0, 0, 0.7)', color: '#fff' }}
                      contentArrowStyle={{ borderRight: '7px solid rgba(0, 0, 0, 0.7)' }}
                  >
                      <div className="experience-header">
                          <img src={exp.image} alt={exp.company} className="company-logo" />
                          <h3>{exp.title}</h3>
                      </div>
                      <h4>{exp.company}</h4>
                      <ul>
                          {exp.description.map((point, i) => (
                              <li key={i}>{point}</li>
                          ))}
                      </ul>
                  </VerticalTimelineElement>
              ))}
          </VerticalTimeline>
      </div>
  );
}

export default Experience;
